<template>
  <div class="Usermanagement">
    <content-title :nav="nav"></content-title>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabslist"
        :key="index"
        :label="item"
        :value="index"
      ></el-tab-pane>
    </el-tabs>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="登录账号">
        <el-input v-model="formInline.id"></el-input>
      </el-form-item>
      <el-form-item v-if="activeName == 1" label="所属客服">
        <el-input v-model="formInline.qu"></el-input>
      </el-form-item>
      <el-form-item label="所属区域">
        <el-input v-model="formInline.qu"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="cxclick">查询</el-button>
      </el-form-item>
    </el-form>
    <el-form>
      <el-form-item label="已选1条">
        <el-button>{{ activeName == 0 ? "分配客服" : "修改客服" }}</el-button>
      </el-form-item>
    </el-form>
    <div v-if="activeName == 0">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="name" label="用户ID"> </el-table-column>
        <el-table-column prop="name" label="用户昵称"> </el-table-column>
        <el-table-column prop="address" label="登录账号"></el-table-column>
        <el-table-column prop="address" label="领券状态"></el-table-column>
        <el-table-column prop="address" label="注册时间"></el-table-column>
        <el-table-column prop="address" label="所属区域"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template>
            <samp style="color: #409eff" @click="dialogVisible = true"
              >分配客服</samp
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="activeName == 1">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="name" label="用户ID"> </el-table-column>
        <el-table-column prop="name" label="用户昵称"> </el-table-column>
        <el-table-column prop="address" label="登录账号"></el-table-column>
        <el-table-column prop="address" label="领券状态"></el-table-column>
        <el-table-column prop="address" label="登录时间"></el-table-column>
        <el-table-column prop="address" label="所属区域"></el-table-column>
        <el-table-column prop="address" label="所属客服"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template>
            <samp style="color: #409eff" @click="dialogVisible = true"
              >修改客服</samp
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分配客服点击窗口 -->
    <el-dialog :visible.sync="dialogVisible" width="400px" title="分配客服">
      <div
        style="display: flex; justify-content: space-between; padding: 0 30px"
      >
        <el-input
          placeholder="请输入客服昵称"
          v-model="formInline.qu"
        ></el-input>
        <el-button type="primary" @click="cxclick">查询</el-button>
      </div>
      <ul
        style="
          border: 1px solid #dcdfe6;
          margin: 20px 30px 0 30px;
          height: 130px;
        "
      >
        <li
          style="
            padding: 0 53px 0 20px;
            height: 33%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span>aaaabbb</span>
          <el-checkbox v-model="checked" @change="checked1(1)"></el-checkbox>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="payTotal"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      dialogVisible: false,
      currentPage: 1,
      payTotal: 100,
      nav: { firstNav: "用户管理中心", secondNav: "用户管理" },
      activeName: 0,
      tabslist: ["未分配", "已分配"],
      formInline: {
        id: "",
        qu: "",
      },
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "8 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "518 弄",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海 1518 弄",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普1518 弄",
        },
      ],
      multipleSelection: [],
    };
  },
  methods: {
    checked1(e) {
      console.log(e, this.checked);
    },
    handleClick() {
      this.currentPage = 1;
    },
    cxclick() {},
    distributionclick(e) {
      console.log(e);
    },
    changeCurrentPage(v) {
      this.currentPage = v;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="less">
.Usermanagement {
  .cell {
    text-align: center;
  }
  .el-pagination {
    width: 100%;
  }
  padding: 33px 20px 0 20px;
  background-color: #ffffff;
}
</style>
